<template>
  <!-- 登录弹窗 -->
  <div class="log-modal">
    <div class="content">
      <div class="top">
        <div class="close-log" @click="closeMask() "></div>
        <img
          class="top-img"
          v-if="siteInfo && siteInfo.login_image"
          :src="siteInfo.login_image"
        />
        <img
          class="top-img"
          v-else
          :src="require('@/assets/log-modal-top-bg.png')"
        />
        <div class="tab-titles">
          <span
            class="tab-title"
            :class="{ active: loginType == 1 }"
            @click="loginType = 1"
            >个人登录</span
          >
          <span
            v-if="siteInfo.id!=73 && siteInfo.id !=96 && siteInfo.id !=100 && siteInfo.id !=79"
            class="tab-title"
            :class="{ active: loginType == 2 }"
            @click="loginType = 2"
            >企业登录</span
          >
        </div>
      </div>
      <div class="tab-content">
        <template v-if="loginType == 1 || loginType == 2">
          <div class="input-box user-name">
            <input type="text" v-model="logInfo.username" />
          </div>
          <div class="tip" v-if="phoneErr">{{ phoneErr }}</div>
          <div class="input-box password">
            <input
              :type="showPass ? 'text' : 'password'"
              v-model="logInfo.password"
            />
            <div
              class="show-pass"
              :class="showPass ? 'open' : 'close'"
              @click="showPass = showPass ? false : true"
            ></div>
          </div>
          <div class="tip" v-if="passErr">{{ passErr }}</div>
        </template>
        <!--微信登录 -->
        <div class="wx_code_box" v-if="loginType == 3">
          <p class="title_tips">请使用微信扫描二维码登录</p>
          <p class="img_code">
            <img :src="qrInfo.qr_code" alt="" v-if="qrInfo.qr_code" />
          </p>
        </div>
        <!-- 绑定手机号 -->
        <div class="login_popover" v-if="loginType == '4'">
          <div class="top_title flex">
            <p class="tips" style="width: 100%; text-align: center">
              绑定手机号
            </p>
          </div>
          <div class="get_code">
            <p class="tips_text">
              应国家网络安全需要，需要通过绑定手机号来完善实名认证机制
            </p>
            <ul>
              <li>
                <div class="input flex">
                  <input
                    :class="bindPhoneNumErr ? 'inputLine' : ''"
                    type="text"
                    placeholder="请输入联系人手机号"
                    v-model="bindPhoneNum"
                    maxlength="11"
                  />
                </div>
                <transition name="fade" transiton="fade">
                  <div class="tips" v-if="bindPhoneNumErr">
                    {{ bindPhoneNumErr }}
                  </div>
                </transition>
              </li>
              <li>
                <div class="flex">
                  <input
                    v-model="bindCode"
                    :class="bindCodeErr ? 'inputLine' : ''"
                    type="text"
                    placeholder="请输入验证码"
                  />
                  <p
                    :class="timer ? 'btnTime btn cursor' : 'btn cursor'"
                    @click="countDown"
                  >
                    {{ getCodeText }}
                  </p>
                </div>
                <transition name="fade" transiton="fade">
                  <div class="tips" v-if="bindCodeErr">
                    {{ bindCodeErr }}
                  </div>
                </transition>
              </li>
            </ul>
            <div class="deepBtn cursor" @click="bindPhone">立即绑定</div>
          </div>
        </div>
        <template v-if="loginType == 1 || loginType == 2">
          <div class="ope">
            <!-- <label class="avoid-login" @click="avoidTheLogin = avoidTheLogin?false:true">
              <div class="radio" :class="avoidTheLogin?'active':''"></div>
              <span>7天内免登录</span>
            </label> -->
            <span class="forgot-pass" @click="forgotPass">忘记密码</span>
            <!-- <router-link to='/changePassword' class="forgot-pass">忘记密码</router-link> -->
          </div>
          <button class="log-btn" @click="logBtn">登录</button>

          <button v-if="siteInfo.id == 106" class="reg-btn" @click="logBtnGrjy">贵人家园登录</button>

          <button class="reg-btn" @click="regBtn" v-if="siteInfo.id!=73&&siteInfo.id!=96 && siteInfo.id !=100 && siteInfo.id !=79" >注册（个人/企业）</button>
        </template>
        <div class="agreement">
          <input type="checkbox" v-model="agree" /> 我已阅读并同意<router-link
            class="agreement-link"
            to="/"
            >《隐私政策》</router-link
          >和<router-link to="/" class="agreement-link"
            >《用户协议》</router-link
          >
        </div>
        <template v-if="siteInfo.is_open_wx_login && siteInfo.is_open_wx_pc_login">
          <div class="weixin_login flex cursor" v-if="loginType == 1 || loginType == 2" @click="weixinBtn()">
            <img src="@/assets/pc/weixin.png" alt="" />
            <p>微信扫码登录</p>
          </div>
          <div class="weixin_login flex cursor" v-else >
            <img src="@/assets/pc/weixin.png" alt="" />
            <p>微信扫码登录</p>
          </div>
        </template>
        
      </div>
    </div>

    <!-- 弹出框 -->
    <div class="mask_main" v-if="shenHeState">
      <div class="main_box">
        <p class="close" @click="shenHeState = false">
          <img src="@/assets/close_code.png" alt="" />
        </p>
        <p class="title">扫码添加运营专员好友，快速通过账号审核！</p>
        <p class="img"><img src="@/assets/code_img.png" alt="" /></p>
        <p class="tips" @click="goBack()">关闭</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import axios from "axios";
export default {
  name: "Bottom",
  data() {
    return {
      loginType: 1, //1、个人登录，2、企业登录 3、微信登录 4、微信登录绑定手机号
      agree: true,
      showPass: false,
      avoidTheLogin: "",
      logInfo: {
        //登录信息
        username: "", //手机号
        password: "", //密码
      },
      phoneErr: "", //手机号错误提示
      passErr: "", //登录报错提示
      shenHeState: false,
      siteInfo: "", //网站信息
      qrInfo: {
        qr_code: "",
      },
      // 绑定手机号
      bindPhoneNum: "", //绑定手机号
      bindPhoneNumErr: "", //绑定手机号
      bindCode: "",
      bindCodeErr: "",
      getCodeText: "获取验证码",
      timer: "",
      timerInterval: "",
      open_id:'',
      clickIndex:'1'
    };
  },
  mounted() {
    this.siteInfo = JSON.parse(localStorage.getItem("siteInfo"));
  },
  methods: {
    ...mapMutations(["CloseLoginModal", "ShowTipModal"]), //登录弹窗
    //登录
    logBtn() {
      if (this.loginType == 1) {
        if (this.logInfo.username == "") {
          this.phoneErr = "请填写手机号";
          return;
        } else {
          let reg = /^0?1[0-9][0-9]\d{8}$/;
          if (!reg.test(this.logInfo.username)) {
            this.phoneErr = "请填写正确的手机号";
            return;
          } else {
            this.phoneErr = "";
          }
        }
        this.$axios.post(`/v1/login/login`, this.logInfo).then((res) => {
          if (res.code == 0) {
            //this.logModal = false;
            this.userInfo = res.data.user;
            localStorage.setItem("auth", JSON.stringify(res.data.auth));
            localStorage.setItem("user", JSON.stringify(res.data.user));
            localStorage.setItem("userInfo", JSON.stringify(res.data.user));
            this.$axios
              .post(`/v1/px/loginRecord`, {}, { useLog: true })
              .then(() => {
                //登录记录
                if (
                  this.$route.name == "Registered" ||
                  this.$route.name == "WelcomeRegistered"
                ) {
                  this.CloseLoginModal();
                  this.$router.push("/index");
                } else {
                  this.$router.go(0); //刷新页面
                }
              });
          } else if (res.code == 2 || res.code == 3) {
            this.shenHeState = true;
          } else {
            this.ShowTipModal({
              text: res.msg, //提示弹窗内容
            });
          }
        });
      } else {
        window.open("http://kskeadmin.kskstudy.com");
      }
    },
    // 贵人家园
    logBtnGrjy() {
			if (this.siteInfo.id === 106) {
				axios.post(`/v1/gzLoginUrl`).then(res => {
					if(res.code == 0){
						window.location.href = res.data;
						localStorage.setItem('mLoginUrl', res.data);
					}else{
						this.ShowTipModal({
							text: res.msg,     //提示弹窗内容
						})
					}
				})
			}
    },
    //注册按钮
    regBtn() {
      //this.logModal = false;
      this.CloseLoginModal();
      this.$router.push("/welcomeRegistered");
    },
    //忘记密码
    forgotPass() {
      this.CloseLoginModal();
      this.$router.push("/changePassword");
    },
    goBack() {
      this.CloseLoginModal();
    },

    // 微信登录
    weixinBtn() {
      this.loginType = 3;
      axios({
        method: "get",
        url: "https://kskapi.kskstudy.com/api/v1/login/getLoginQrCode",
        data: {},
      }).then((res) => {
        this.qrInfo = res;
        this.timerInterval = setInterval(() => {
          this.getOpenId();
        }, 2000);
      });
    },
    getOpenId() {
      this.$axios
        .post(`/v1/login/wxLogin`, { openid_key: this.qrInfo.qr_str })
        .then((res) => {
          // code=0 已绑定手机号，无需在绑定  2需要绑定手机号 1不管
          if (res.code == 0) {
            clearInterval(this.timerInterval);
            this.userInfo = res.data.user;
            localStorage.setItem("auth", JSON.stringify(res.data.auth));
            localStorage.setItem("user", JSON.stringify(res.data.user));
            localStorage.setItem("userInfo", JSON.stringify(res.data.user));

            this.$axios.post(`/v1/px/loginRecord`, {}, {useLog: true}).then(() => {  //登录记录
              this.loginType = 5;
              let timerInterval=setInterval(()=>{
                this.timeNum--
                if(this.timeNum==0){
                  clearInterval(timerInterval)
                }
              },1000)
              setTimeout(() => {
                if (this.goIndex) {
                  this.$router.push("/index");
                  this.CloseLoginModal();
                } else {
                  location.reload(); //刷新页面
                  this.CloseLoginModal();
                }
              }, 2500);
            })
          }
          if (res.code == 2) {
            clearInterval(this.timerInterval);

            this.loginType = 4;
            this.open_id = res.data.openid;
          }
        });
    },
     // 绑定手机号
    // 获取验证码
    countDown() {
      if (this.bindPhoneNum && this.clickIndex == "1") {
        this.$axios.post(`https://kskapi.kskstudy.com/common/sendSms`, {
            phone: this.bindPhoneNum,
            sms_code_use: "wxBindPhone",
          }).then((res) => {
            if (res.code == 0) {
              this.clickIndex = "2";
              let time = 60;
              this.getCodeText = time + "秒后再获取";
              this.timer = setInterval(() => {
                time--;
                if (time == 0) {
                  this.getCodeText = "获取验证码";
                  clearInterval(this.timer);
                  this.timer = "";
                  this.clickIndex = "1";
                } else {
                  this.getCodeText = time + "秒后再获取";
                }
              }, 1000);
            } else {
              this.bindCodeErr = res.msg;
            }
          });
      } else {
        this.bindPhoneNumErr = "请输入手机号";
      }
    },
    bindPhone() {
      this.$axios
        .post(`/v1/px/login/bindPhone`, {
          phone: this.bindPhoneNum,
          code: this.bindCode,
          open_id: this.open_id,
        }).then((res) => {
          if (res.code == 0) {
            this.loginType = 5;
            localStorage.setItem("auth", JSON.stringify(res.data.auth));
            localStorage.setItem("user", JSON.stringify(res.data.user));
            localStorage.setItem("userInfo", JSON.stringify(res.data.user));
            location.reload(); //刷新页面
            setTimeout(() => {
              this.CloseLoginModal();
            }, 1500);
            this.loginType = 1
          } else {
            this.$message.error(res.msg);
            // this.ShowTipModal({
            //   text: res.msg,
            // });
          }
        });
    },
    closeMask(){
      this.CloseLoginModal()
       clearInterval(this.timerInterval);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.log-modal {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99999;
  .content {
    width: 420px;
    background-color: #fff;
    border-radius: 8px;
  }
  .top {
    width: 100%;
    height: 200px;
    // background: url(~@/assets/log-modal-top-bg.png) no-repeat;
    // background-size: 100% auto;
    position: relative;
    .top-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      position: absolute;
      z-index: 0;
    }
    .close-log {
      width: 30px;
      height: 30px;
      background: url(~@/assets/close-ico.png) no-repeat;
      background-size: 100%;
      position: absolute;
      right: -50px;
      top: 0;
      cursor: pointer;
    }
    .tab-titles {
      width: 100%;
      padding-bottom: 10px;
      position: absolute;
      bottom: 0;
      z-index: 1;
    }
    .tab-title {
      font-size: 18px;
      color: #fff;
      text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.3);
      margin: 0 17px;
      position: relative;
      cursor: pointer;
      &.active {
        font-weight: 900;
        &::after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border: 6px solid #fff;
          position: absolute;
          bottom: -20px;
          left: 30px;
          transform: rotate(45deg);
        }
      }
    }
  }
  .tab-content {
    box-sizing: border-box;
    width: 100%;
    min-height: 368px;
    padding: 34px 34px 30px;
    position: relative;
  }
  .weixin_login {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: absolute;
    left: 0;
    bottom: -36px;
    background: #f5f5f5;
    width: 100%;
    height: 54px;
    justify-content: center;
    border-radius: 0 0 5px 5px;
    p {
      font-size: 12px;
      color: #999999;
      margin-left: 9px;
    }
  }
  .input-box {
    box-sizing: border-box;
    width: 100%;
    height: 44px;
    background: #fff;
    //box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    border: 1px solid #e5e5e5;
    padding-left: 60px;
    position: relative;
    overflow: hidden;
    input {
      width: 100%;
      height: 42px;
      line-height: 42px;
    }
    &::after {
      content: "";
      display: block;
      width: 1px;
      height: 14px;
      background-color: #d8d8d8;
      position: absolute;
      left: 45px;
      top: 15px;
    }
    &.user-name {
      padding-top: 0;
      margin-bottom: 46px;
      background: url(~@/assets/phone-grey-ico.png) no-repeat 12px center;
      background-size: auto 24px;
    }
    &.password {
      background: url(~@/assets/lock-open-ico.png) no-repeat 12px center;
      background-size: auto 24px;
      margin-bottom: 10px;
      input {
        width: 80%;
      }
      .show-pass {
        width: 28px;
        height: 28px;
        position: absolute;
        right: 16px;
        top: 50%;
        margin-top: -14px;
        cursor: pointer;
        &.open {
          background: url(~@/assets/eye-open-ico.png) no-repeat 0 center;
          background-size: 100% auto;
        }
        &.close {
          background: url(~@/assets/eye-close-ico.png) no-repeat 0 center;
          background-size: 100% auto;
        }
      }
    }
  }
  .tip {
    box-sizing: border-box;
    width: 100%;
    padding: 0 30px 0 34px;
    font-size: 12px;
    color: #cd1b14;
    text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 90px;
    left: 0;
  }
  .ope {
    overflow: hidden;
    margin-bottom: 24px;
    .avoid-login,
    a {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #666;
      text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.3);
      //禁用双击选中
      -moz-user-select: none; /*火狐*/
      -webkit-user-select: none; /*webkit浏览器*/
      -ms-user-select: none; /*IE10*/
      -khtml-user-select: none; /*早期浏览器*/
      user-select: none;
      cursor: pointer;
    }
    .radio {
      box-sizing: border-box;
      width: 14px;
      height: 14px;
      border: 1px solid #d8d8d8;
      border-radius: 50%;
      margin-right: 10px;
      &.active {
        background: url(~@/assets/selected-ico.png) no-repeat center center;
        background-size: auto 14px;
      }
    }
  }
  .reg-btn,
  .log-btn {
    width: 100%;
    height: 44px;
    line-height: 44px;
    border-radius: 6px;
    font-size: 16px;
    text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.3);
    letter-spacing: 2px;
    margin-bottom: 14px;
    cursor: pointer;
  }
  .log-btn {
    background-color: #254ed4;
    color: #fff;
    //box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.3);
  }
  .reg-btn {
    background: #fff;
    border-radius: 6px;
    color: #333;
    border: 1px solid #e5e5e5;
  }
  .agreement {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #666;
    text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.3);
    input {
      margin-right: 4px;
      cursor: pointer;
    }
    .agreement-link {
      color: #254ed4;
    }
  }
}
.forgot-pass {
  float: right;
  font-size: 12px;
  color: #666;
  cursor: pointer;
}

// 审核弹出框
.mask_main {
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  .main_box {
    width: 400px;
    height: 320px;
    background: #ffffff;
    border-radius: 5px 5px 5px 5px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    p.close {
      margin: 20px;
      display: flex;
      justify-content: flex-end;
    }
    p.title {
      text-align: center;
      margin-top: 32px;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
    }
    p.img {
      width: 130px;
      height: 130px;
      margin: 20px auto;
    }
    p.tips {
      text-align: center;
      margin-top: 8px;
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }
  }
}

// 微信扫码登录
.wx_code_box {
  margin-top: 4px;
  padding-bottom: 15px;
  p.title_tips {
    font-size: 16px;
    color: #333333;
    text-align: center;
  }
  p.img_code {
    width: 240px;
    height: 240px;
    margin: 17px auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

// 绑定手机号
.login_popover {
  div.top_title {
    p.tips {
      text-align: center;
      font-size: 20px;
      color: #333;
    }
  }
  .get_code {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 17px 30px 20px;
    // justify-content: center;
    p.tips_text {
      font-size: 14px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #999999;
    }
    ul {
      margin-top: 38px;
      li {
        margin-bottom: 15px;
        position: relative;
        height: 56px;
        input {
          width: 100%;
        }
        div.tips {
          position: absolute;
          left: 0;
          bottom: 0;
          color: #e15416;
        }
      }
    }
    input {
      width: 220px;
      height: 38px;
      padding-left: 11px;
      border: 1px solid #eeeeee;
      border-radius: 5px;
    }

    p.btn {
      width: 140px;
      height: 38px;
      background: #0065df;
      border-radius: 4px 4px 4px 4px;
      margin-left: 32px;
      text-align: center;
      line-height: 38px;
      color: #fff;
      font-size: 14px;
    }
    p.btnTime {
      background: #999;
    }
    div.deepBtn {
      width: 329px;
      height: 38px;
      background: #0065df;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      line-height: 38px;
      text-align: center;
      margin-top: 8px;
    }
  }
}

.flex {
  display: flex;
  align-items: center;
}
</style>
