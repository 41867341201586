import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Axios from '@/utils/axios'
import store from './store'
import VueLazyload from 'vue-lazyload'
import 'lib-flexible'
import Qs from 'qs'
import MD5 from 'js-md5';
import Meta from "vue-meta";

import 'element-ui/lib/theme-chalk/index.css'
import element from './element/index'
Vue.use(element)
Vue.use(Meta)
Vue.config.productionTip = false
Vue.prototype.$axios = Axios; //引用axios
Vue.prototype.$MD5 = MD5;
Vue.use(VueLazyload)

// 引用微信
import share from "@/utils/share.js";

Vue.prototype.shareWx = share // 引用axios
Vue.config.ignoredElements = ['wx-open-launch-weapp', 'wx-open-subscribe']

// 引入富文本插件
import VueQuillEditor from 'vue-quill-editor'
// 引入富文本样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
// 使用富文本
Vue.use(VueQuillEditor)


router.beforeEach(async(to, from, next) => {
  if(to.fullPath.includes('/#/')){
    next(to.fullPath.split('/#')[1]);
  }else{
    next()
  }
})

new Vue({
  router,
  Qs,
  store,
  render: h => h(App)
}).$mount('#app')
