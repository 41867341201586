import wx from 'weixin-js-sdk' // 步骤1、引入微信js-sdk
import axios from 'axios' // 步骤2、引入axios，请求接口


// let appId, timestamp, nonceStr, signature,jsApiList

// 步骤3、请求后台接口，获取微信配置的参数
const getWxConfig = async (pageUrl) => {
    // const params = {
    //     url: pageUrl, // 需要进行微信分享的所在页面url
    //     // token: token, // 项目中的 token
    //     // code: code1 // 项目中的 code 
    // }
    console.log(pageUrl,'pageUrl')
    const { data, code } = await axios.get(`https://kskapi.kskstudy.com/api/v1/live/wxShare?url=`+location.href)
    // const { data, code } = await axios.post(`/v1/live/wxShare`,{ url: location.href })
    if (code != 1) return
    // appId = data.appId
    // timestamp = data.timestamp
    // nonceStr = data.nonceStr
    // signature = data.signature
    // jsApiList = data.jsApiList
    wx.config({
        debug: false,
        appId: data.appId,
        timestamp: data.timestamp,
        nonceStr: data.nonceStr,
        signature: data.signature,
        jsApiList: data.jsApiList
    })
}

// 步骤4、通过config接口注入权限验证配置
// const setWxConfig = (appId, timestamp, nonceStr, signature,jsApiList) => {
//     wx.config({
//         debug: false,
//         appId: appId,
//         timestamp: timestamp,
//         nonceStr: nonceStr,
//         signature: signature,
//         jsApiList: jsApiList
//     })
// }

// 步骤5、通过ready接口处理成功验证
const wxReady = (shareObj) => {
    wx.ready(function () {
        // debugger
        // ① 分享到朋友圈
        wx.updateTimelineShareData({
            title: shareObj.title, // 分享标题
            link: shareObj.link2, // 分享链接
            desc: shareObj.desc, // 分享描述
            imgUrl: shareObj.imgUrl, // 分享图标
            success: function () {
                // alert('成功')

                // 用户确认分享后执行的回调函数
                // console.log('成功')
            },
            cancel: function () {
                // alert('失败')
                // console.log('失败')
                // 用户取消分享后执行的回调函数
            }
        });
        // ② 分享给朋友
        wx.updateAppMessageShareData({
            title: shareObj.title, // 分享标题
            link: shareObj.link2, // 分享链接
            desc: shareObj.desc, // 分享描述
            imgUrl: shareObj.imgUrl, // 分享图标
            success: function () {
                // alert('成功')

                // 用户确认分享后执行的回调函数
                // console.log('成功')
            },
            cancel: function () {
                // alert('失败')
                // console.log('失败')
                // 用户取消分享后执行的回调函数
            }
        });

    })
}
// 步骤6、通过error接口处理失败验证
const wxError = () => {
    wx.error(function (err) {
        console.log(err)
    })
}

// 分享
const wxShare = async (pageUrl, shareObj) => {
    await getWxConfig(pageUrl)
    // await setWxConfig(appId, timestamp, nonceStr, signature,jsApiList)
    await wxReady(shareObj)
    await wxError()
}

export default wxShare