<template>
  <!-- 登录弹窗 -->
  <div class="log-modal m">
    <div class="page-top"><div class="close" @click="closeBtn"></div>登录</div>
    <div class="content">
      <!-- <div class="tab-titles">
        <span class="tab-title active">个人登陆</span>
        <span class="tab-title">企业登陆</span>
      </div> -->
      <div class="tab-content">
        <img class="img" :src="require('@/assets/head-default-personal.png')">
        <div class="input-box user-name">
          <input type="number" v-model="logInfo.username">
          <div class="tip" v-if="phoneErr">{{phoneErr}}</div>
        </div>
        <div class="input-box password">
          <input :type="showPass? 'text': 'password'" v-model="logInfo.password">
          <div class="show-pass" :class="showPass?'open':'close'" @click="showPass = showPass?false:true"></div>
          <div class="tip" v-if="passErr">{{passErr}}</div>
        </div>
        <div class="ope">
          <!-- <label class="avoid-login" @click="avoidTheLogin = avoidTheLogin?false:true">
            <div class="radio" :class="avoidTheLogin?'active':''"></div>
            <span>7天内免登录</span>
          </label> -->
          <span class="forgot-pass" @click="forgotPass">忘记密码</span>
          <!-- <router-link to='/changePassword' class="forgot-pass">忘记密码</router-link> -->
        </div>
        <button class="log-btn" @click="logBtn">登录</button>
        <button v-if="siteInfo.id == 106" class="reg-btn" @click="logBtnGrjy">贵人家园登录</button>
        <button class="reg-btn" @click="regBtn" v-if="set_info.id !=73&&set_info.id !=96&& set_info.id !=100&& set_info.id !=79">注册（个人/企业）</button>
        <!-- <div class="agreement">
          <label><input type="checkbox" v-model="agree">我已阅读并同意</label><router-link class="agreement-link" to='/'>《隐私政策》</router-link>和<router-link to='/' class="agreement-link">《用户协议》</router-link>
        </div> -->
      </div>
    </div>
    <div class="wx-login" v-if="isWeiXin && set_info.is_open_wx_login" @click="wxLogin">微信登录<img class="wx-logo" src="~@/assets/pc/weixin.png"></div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { IsWeiXin } from '@/utils/util'
import axios from '@/utils/axios'
export default {
  name: 'Bottom',
  props: ['set_info'],
  data(){
    return {
      agree: true,
      showPass: false,
      avoidTheLogin: '',
      logInfo: {          //登录信息
        username: '',     //手机号
        password: ''      //密码
      },
      phoneErr: '',       //手机号错误提示
      passErr: '',        //登录报错提示
      isWeiXin: false,    //是否在微信
      siteInfo: '',
    }
  },
   mounted(){
    this.isWeiXin = IsWeiXin();
    if(localStorage.getItem('siteInfo')){
      this.siteInfo = JSON.parse(localStorage.getItem('siteInfo'))
    }
  },
  methods: {
    ...mapMutations(['CloseMLoginModal', 'ShowTipModal', 'ClearUserInfo']), //登录弹窗
    //登录
    logBtn(){
      if(this.logInfo.username == ''){
        this.phoneErr = '请填写手机号';
      }else{
        let reg = /^0?1[0-9][0-9]\d{8}$/;

        if(!reg.test(this.logInfo.username)){
          this.phoneErr = '请填写正确的手机号';
          return;
        }else{
          this.phoneErr = '';
        }
      }

      this.$axios.post(`/v1/login/login`, this.logInfo).then(res => {
        if(res.code == 0){
          this.userInfo = res.data.user;
          localStorage.setItem('auth', JSON.stringify(res.data.auth));
          localStorage.setItem('user', JSON.stringify(res.data.user));
          localStorage.setItem('userInfo', JSON.stringify(res.data.user));
          this.$axios.post(`/v1/px/loginRecord`, {}, {useLog: true}).then(() => {  //登录记录
            location.reload();
            this.CloseMLoginModal();
          })
        }else{
          this.ShowTipModal({
            text: res.msg,     //提示弹窗内容
          })
        }
      })
    },
    // 贵人家园
    logBtnGrjy() {
			if (this.siteInfo.id === 106) {
				axios.post(`/v1/gzLoginUrl`).then(res => {
					if(res.code == 0){
						window.location.href = res.data;
						localStorage.setItem('mLoginUrl', res.data);
					}else{
						this.ShowTipModal({
							text: res.msg,     //提示弹窗内容
						})
					}
				})
			}
    },
    //注册按钮
    regBtn(){
      //this.logModal = false;
      this.CloseMLoginModal();
      this.$router.push('/welcomeRegistered');
    },
    
    //微信登录
    wxLogin(){
      this.$axios.post(`/v1/login/wxCode`, {
        source: 1,
        return_url: window.location.href.split('/#')[0],
      }).then(res => {
        window.location.href = res.data.url;
      })
    },
    //忘记密码
    forgotPass(){
      this.CloseMLoginModal();
      this.$router.push('/userCenter/changePassword');
    },
    //关闭登录
    closeBtn(){
      let url = this.$route.fullPath;
      if(url.includes('userCenter')){
        localStorage.removeItem('user');
        localStorage.removeItem('auth');
        localStorage.removeItem('userInfo');
        this.ClearUserInfo();
        this.$router.push('/index');
        this.CloseMLoginModal();
      }else{
        this.CloseMLoginModal();
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.log-modal{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: 8888;
  .page-top{
    height: 88px;
    line-height: 88px;
    font-size: 36px;
    color: #333;
    text-align: center;
    font-weight: bold;
    position: relative;
    .close{
      width: 88px;
      height: 88px;
      background: url(~@/assets/close-grey-ico.png) no-repeat center center;
      background-size: 30px auto;
      position: absolute;
      top: 0;
      left: 0;
    }
  }  
  .tab-content{
    box-sizing: border-box;
    padding: 88px 48px 0;
    .img{
      display: block;
      width: 156px;
      height: 156px;
      margin: 0 auto;
      margin-bottom: 88px;
    }
  }
  .input-box{
    box-sizing: border-box;
    width: 100%;
    border-radius: 6px;
    border: 1px solid #E5E5E5;
    padding-left: 116px;
    position: relative;
    input{
      width: 100%;
      padding: 25px 0;
      font-size: 28px;
      color: #333;
      &::placeholder{
        color: #999;
      }
    }
    &::after{
      content: '';
      display: block;
      width: 1px;
      height: 28px;
      background-color: #D8D8D8;
      position: absolute;
      left: 90px;
      top: 50%;
      margin-top: -14px;
    }
    .tip{
      box-sizing: border-box;
      width: 100%;
      font-size: 24px;
      color: #CD1B14;
      position: absolute;
      top: 100px;
      left: 0;
    }
    &.user-name{
      padding-top: 0;
      margin-bottom: 70px;
      background: url(~@/assets/phone-grey-ico.png) no-repeat 24px center;
      background-size: auto 50px;
    }
    &.password{
      background: url(~@/assets/lock-open-ico.png) no-repeat 22px center;
      background-size: auto 50px;
      margin-bottom: 20px;
      input{
        width: 80%;
      }
      .show-pass{
        width: 52px;
        height: 52px;
        position: absolute;
        right: 16px;
        top: 50%;
        margin-top: -26px;
        &.open{
          background: url(~@/assets/eye-open-ico.png) no-repeat 0 center;
          background-size: 100% auto;
        }
        &.close{
          background: url(~@/assets/eye-close-ico.png) no-repeat 0 center;
          background-size: 100% auto;
        }
      }
    }
  }
  .ope{
    margin-bottom: 60px;
    overflow: hidden;
    .avoid-login,
    a{
      display: flex;
      align-items: center;
      font-size: 24px;
      color: #666;
    }
    .radio{
      box-sizing: border-box;
      width: 24px;
      height: 24px;
      border: 1px solid #D8D8D8;
      border-radius: 50%;
      margin-right: 10px;
      &.active{
        background: url(~@/assets/selected-ico.png) no-repeat center center;
        background-size: auto 24px;
      }
    }
    .forgot-pass{
      float: right;
    }
  }
  .reg-btn,
  .log-btn{
    width: 100%;
    height: 88px;
    line-height: 88px;
    border-radius: 6px;
    font-size: 28px;
    letter-spacing:2px;
    margin-bottom: 14px;
  }
  .log-btn{
    background-color: #254ED4;
    color: #FFF;
  }
  .reg-btn{
    background: #FFF;
    border-radius: 6px;
    color: #333;
    border: 1px solid #e5e5e5
  }
  .agreement{
    display: flex;
    align-items: center;
    font-size: 24px;
    color: #666;
    text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.3);
    input{
      width: 24px;
      height: 24px;
      margin-right: 4px;
    }
    .agreement-link{
      color: #254ED4;
    }
  }
}
.forgot-pass{
  font-size: 24px;
  color: #666;
}
.wx-login{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #666;
  margin-top: 50px;
  img{
    margin-left: 10px;
  }
}
</style>
